section.result {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.task-wrapper {
    min-height: 150px;
    height: 100%;
    background-color: $purple;
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 9px;
    text-align: center;

    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }

    h2 {
        margin-top: 0;
        font-size: 1.4rem;
    }

    h3 {
        margin: 0;
        font-size: 1rem;
        font-weight: 300;
    }

    p {
        margin-top: auto;
        padding-top: 20px;
        margin-bottom: 0;
        justify-self: flex-end;
    }

}