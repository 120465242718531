span.player-name {
    display: block;
    width: max-content;
    background-color: $purple;
    font-weight: 300;
    color: #fff;
    padding: .6em 1.2em;
    border-radius: 9999999px;
    font-size: 1rem;
    margin-bottom: 10px;
    position: relative;

    &:not(:last-of-type) {
        margin-right: .8em;
    }

    span.remove-player {
        position: absolute;
        display: grid;
        place-content: center;
        width: 25px;
        height: 25px;
        right: -8px;
        top: -8px;
        background-color: #fff;
        color: $main-red;
        border: 1px solid $main-red;
        border-radius: 9999999999px;
        -webkit-box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.45);
        -moz-box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.45);
        box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.45);

        svg {
            width: 25px;
            height: 25px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}