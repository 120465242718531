footer {
    padding: 0;
    margin: 0;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    border-radius: 2px;
    padding: 2px 5px;
    background-color: $beige;

    a {
        font-size: 12px;
        color: $dark-blue;
        margin-right: 5px;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            opacity: .8;
        }
    }
}