@font-face {
    font-family: "Ubuntu";
    src: local("Ubuntu Bold"), local("Ubuntu-Bold"),
        url("../fonts/Ubuntu-Bold.woff2") format("woff2"),
        url("../fonts/Ubuntu-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Ubuntu";
    src: local("Ubuntu Light"), local("Ubuntu-Light"),
        url("../fonts/Ubuntu-Light.woff2") format("woff2"),
        url("../fonts/Ubuntu-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Ubuntu";
    src: local("Ubuntu Bold Italic"), local("Ubuntu-BoldItalic"),
        url("../fonts/Ubuntu-BoldItalic.woff2") format("woff2"),
        url("../fonts/Ubuntu-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Ubuntu";
    src: local("Ubuntu Light Italic"), local("Ubuntu-LightItalic"),
        url("../fonts/Ubuntu-LightItalic.woff2") format("woff2"),
        url("../fonts/Ubuntu-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Ubuntu";
    src: local("Ubuntu Medium Italic"), local("Ubuntu-MediumItalic"),
        url("../fonts/Ubuntu-MediumItalic.woff2") format("woff2"),
        url("../fonts/Ubuntu-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Ubuntu";
    src: local("Ubuntu Medium"), local("Ubuntu-Medium"),
        url("../fonts/Ubuntu-Medium.woff2") format("woff2"),
        url("../fonts/Ubuntu-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


$main-font: "Ubuntu", sans-serif;