section {
    &.intro {
        margin-bottom: 30px;
    }

    &.players {
        padding: 15px 0 5px;
        display: flex;
        flex-flow: row wrap;

        &.no-remove {
            padding-top: 5px;
        }
    }

}