details.accordion {
    margin-bottom: 25px;

    summary {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;
        background-color: $dark-blue;
        color: #fff;
        padding: 1em 53px 1em 20px;
        font-size: 1.2rem;
        border-radius: 9px;
        
        span {
            justify-self: flex-end;
            margin-left: auto;
            font-weight: 300;
            font-size: .9rem;
            padding-left: 20px;
        }

        &::after {
            content: "\002B";
            position: absolute;
            display: grid;
            place-content: center;
            right: 20px;
            font-weight: 300;
            font-size: 2.5rem;
            top: 47%;
            transform: translateY(-50%);
        }
        
        &::marker {
            display: none;
            content: "";
        }

        &:hover {
            cursor: pointer;
        }

    }

    .acc-inner-content {
        border: 3px solid $dark-blue;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 20px;
        display: none;

        h4 {
            margin-top: 0;
        }
    }

    &.open {
        summary {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &::after {
                content: "\2212";
            }
        }

        .acc-inner-content {
            display: block;
        }
    }
}