* {
    box-sizing: border-box;
}

html, body {
    font-family: $main-font;
    width: 100%;
    margin: 0;
    padding: 0;
}

html {
    font-size: 16px;
    background-color: $beige;
    color: $purple;
}

body {
    background-color: $beige;
    max-width: $max-width;
    margin: 0 auto;
    padding: 2% 0;
    color: initial;
    position: relative;

    @media (max-width: 400px) {
        hyphens: auto;
    }
}

hr {
    margin: 30px 0;
    border: 3px #E0D8C8 solid;
    border-radius: 9999999px;
}