header {
    margin-bottom: 50px;

    .random-gif {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            background-color: $dark-blue;
            height: 65%;
            width: 100%;
            transform: translateY(-50%);
            z-index: 1;
        }
        img {
            position: relative;
            z-index: 2;
            display: block;
            margin: 0 auto;
        }
    }
}