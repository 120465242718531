@mixin main-btn {
    background-color: $dark-blue;
    font-size: 1.1rem;
    padding: .8em 1.2em;
    color: #fff;
    font-weight: 500;
    border-radius: 9px;
    border: none;
    display: block;
    font-family: $main-font;
    appearance: none;

    &:hover {
        //background-color: lighten($dark-blue, 5%);
        background-color: #3B4560;
        cursor: pointer;
    }
}

button {
    @include main-btn;

    &.assign-tasks-btn {
        margin-bottom: 20px;
    }

    &.secondary {
        background-color: #737d9a;
        
        &:hover {
            background-color: $dark-blue;
            cursor: pointer;
        }
    }
}

.button-group {
    &.horizontal {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 15px;
        margin-top: 15px;

        button {
            margin-bottom: 10px;
            
            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
}