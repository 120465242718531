@charset "UTF-8";
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu Bold"), local("Ubuntu-Bold"), url("../fonts/Ubuntu-Bold.woff2") format("woff2"), url("../fonts/Ubuntu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu Light"), local("Ubuntu-Light"), url("../fonts/Ubuntu-Light.woff2") format("woff2"), url("../fonts/Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu Bold Italic"), local("Ubuntu-BoldItalic"), url("../fonts/Ubuntu-BoldItalic.woff2") format("woff2"), url("../fonts/Ubuntu-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu Light Italic"), local("Ubuntu-LightItalic"), url("../fonts/Ubuntu-LightItalic.woff2") format("woff2"), url("../fonts/Ubuntu-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu Medium Italic"), local("Ubuntu-MediumItalic"), url("../fonts/Ubuntu-MediumItalic.woff2") format("woff2"), url("../fonts/Ubuntu-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu Medium"), local("Ubuntu-Medium"), url("../fonts/Ubuntu-Medium.woff2") format("woff2"), url("../fonts/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
h1 {
  font-weight: 700;
  font-size: 2.5rem;
  color: #058ED9;
  position: relative;
  margin-bottom: 0.5em;
}
h1:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -5px;
  height: 5px;
  width: 1.4em;
  background-color: #DE1A1A;
  border-radius: 9999px;
}

h2, h3 {
  color: #222E50;
  font-weight: 500;
}

h2 {
  font-size: 1.5rem;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
}

h3 {
  font-size: 1.3rem;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
  font-weight: 500;
}

h4 {
  margin-bottom: 0.4em;
  font-size: 1.1rem;
  font-weight: 500;
  color: #222E50;
}

p {
  color: #222E50;
  margin-top: 0;
  font-size: 1.1rem;
  line-height: 120%;
  font-weight: 300;
}

b, strong {
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: "Ubuntu", sans-serif;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  background-color: #F4EBD9;
  color: #A53860;
}

body {
  background-color: #F4EBD9;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2% 0;
  color: initial;
  position: relative;
}
@media (max-width: 400px) {
  body {
    hyphens: auto;
  }
}

hr {
  margin: 30px 0;
  border: 3px #E0D8C8 solid;
  border-radius: 9999999px;
}

section.result {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.task-wrapper {
  min-height: 150px;
  height: 100%;
  background-color: #A53860;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
  text-align: center;
}
.task-wrapper:not(:last-of-type) {
  margin-bottom: 1rem;
}
.task-wrapper h2 {
  margin-top: 0;
  font-size: 1.4rem;
}
.task-wrapper h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
}
.task-wrapper p {
  margin-top: auto;
  padding-top: 20px;
  margin-bottom: 0;
  justify-self: flex-end;
}

header {
  margin-bottom: 50px;
}
header .random-gif {
  position: relative;
}
header .random-gif::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #222E50;
  height: 65%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
}
header .random-gif img {
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
}

footer {
  padding: 0;
  margin: 0;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  border-radius: 2px;
  padding: 2px 5px;
  background-color: #F4EBD9;
}
footer a {
  font-size: 12px;
  color: #222E50;
  margin-right: 5px;
  font-weight: 400;
  text-decoration: none;
}
footer a:hover {
  opacity: 0.8;
}

main {
  padding: 0 4%;
  margin: 30px auto;
}

span.player-name {
  display: block;
  width: max-content;
  background-color: #A53860;
  font-weight: 300;
  color: #fff;
  padding: 0.6em 1.2em;
  border-radius: 9999999px;
  font-size: 1rem;
  margin-bottom: 10px;
  position: relative;
}
span.player-name:not(:last-of-type) {
  margin-right: 0.8em;
}
span.player-name span.remove-player {
  position: absolute;
  display: grid;
  place-content: center;
  width: 25px;
  height: 25px;
  right: -8px;
  top: -8px;
  background-color: #fff;
  color: #DE1A1A;
  border: 1px solid #DE1A1A;
  border-radius: 9999999999px;
  -webkit-box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.45);
}
span.player-name span.remove-player svg {
  width: 25px;
  height: 25px;
}
span.player-name span.remove-player:hover {
  cursor: pointer;
}

button {
  background-color: #222E50;
  font-size: 1.1rem;
  padding: 0.8em 1.2em;
  color: #fff;
  font-weight: 500;
  border-radius: 9px;
  border: none;
  display: block;
  font-family: "Ubuntu", sans-serif;
  appearance: none;
}
button:hover {
  background-color: #3B4560;
  cursor: pointer;
}
button.assign-tasks-btn {
  margin-bottom: 20px;
}
button.secondary {
  background-color: #737d9a;
}
button.secondary:hover {
  background-color: #222E50;
  cursor: pointer;
}

.button-group.horizontal {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
  margin-top: 15px;
}
.button-group.horizontal button {
  margin-bottom: 10px;
}
.button-group.horizontal button:not(:last-of-type) {
  margin-right: 10px;
}

form {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
}
form label {
  display: block;
  margin-bottom: 0.4em;
  font-size: 0.9rem;
  font-weight: 500;
  flex: 0 0 100%;
}
form input[type=text] {
  border-radius: 9px;
  border: 2px #DE1A1A solid;
  padding: 0.5rem;
  background-color: #E0D8C8;
  min-width: 300px;
  font-size: 16px;
}
form input[type=text]:focus {
  outline: none;
  border-color: #058ED9;
  background-color: rgba(224, 216, 200, 0.9058823529);
}
@media (max-width: 500px) {
  form input[type=text] {
    min-width: 0;
    width: 100%;
    margin-bottom: 5px;
    height: 47px;
  }
}
form input[type=submit] {
  background-color: #222E50;
  font-size: 1.1rem;
  padding: 0.8em 1.2em;
  color: #fff;
  font-weight: 500;
  border-radius: 9px;
  border: none;
  display: block;
  font-family: "Ubuntu", sans-serif;
  appearance: none;
  margin-left: 20px;
}
form input[type=submit]:hover {
  background-color: #3B4560;
  cursor: pointer;
}
@media (max-width: 500px) {
  form input[type=submit] {
    margin-left: 0;
  }
}
form.task-form {
  display: block;
}
form.task-form input[type=text] {
  height: 47px;
  margin-bottom: 15px;
}
form.task-form input[type=submit] {
  margin-left: 0;
}
form.player-form, form.task-form {
  margin-top: 15px;
}

section.intro {
  margin-bottom: 30px;
}
section.players {
  padding: 15px 0 5px;
  display: flex;
  flex-flow: row wrap;
}
section.players.no-remove {
  padding-top: 5px;
}

details.accordion {
  margin-bottom: 25px;
}
details.accordion summary {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  background-color: #222E50;
  color: #fff;
  padding: 1em 53px 1em 20px;
  font-size: 1.2rem;
  border-radius: 9px;
}
details.accordion summary span {
  justify-self: flex-end;
  margin-left: auto;
  font-weight: 300;
  font-size: 0.9rem;
  padding-left: 20px;
}
details.accordion summary::after {
  content: "+";
  position: absolute;
  display: grid;
  place-content: center;
  right: 20px;
  font-weight: 300;
  font-size: 2.5rem;
  top: 47%;
  transform: translateY(-50%);
}
details.accordion summary::marker {
  display: none;
  content: "";
}
details.accordion summary:hover {
  cursor: pointer;
}
details.accordion .acc-inner-content {
  border: 3px solid #222E50;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 20px;
  display: none;
}
details.accordion .acc-inner-content h4 {
  margin-top: 0;
}
details.accordion.open summary {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
details.accordion.open summary::after {
  content: "−";
}
details.accordion.open .acc-inner-content {
  display: block;
}