h1 {
    font-weight: 700;
    font-size: 2.5rem;
    color: $main-blue;
    position: relative;
    margin-bottom: .5em;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -5px;
        height: 5px;
        width: 1.4em;
        background-color: $main-red;
        border-radius: 9999px;
    }
}

h2, h3 {
    color: $dark-blue;
    font-weight: 500;
}

h2 {
    font-size: 1.5rem;
    margin-top: .5em;
    margin-bottom: .3em;
}

h3 {
    font-size: 1.3rem;
    margin-top: .5em;
    margin-bottom: .3em;
    font-weight: 500;
}

h4 {
    margin-bottom: .4em;
    font-size: 1.1rem;
    font-weight: 500;
    color: $dark-blue;
}

p {
    color: $dark-blue;
    margin-top: 0;
    font-size: 1.1rem;
    line-height: 120%;
    font-weight: 300;
}

b, strong {
    font-weight: 500;
}