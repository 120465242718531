form {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 20px;

    label {
        display: block;
        margin-bottom: .4em;
        font-size: .9rem;
        font-weight: 500;
        flex: 0 0 100%;
    }

    input {

        &[type=text] {
            border-radius: 9px;
            border: 2px $main-red solid;
            padding: .5rem;
            background-color: #E0D8C8;
            min-width: 300px;
            font-size: 16px;
            
            &:focus {
                outline: none;
                border-color: $main-blue;
                background-color: #e0d8c8e7;
            }

            @media (max-width: 500px) {
                min-width: 0;
                width: 100%;
                margin-bottom: 5px;
                height: 47px;
            }
        }

        &[type=submit] {
            @include main-btn;
            margin-left: 20px;

            @media (max-width: 500px) {
                margin-left: 0;
            }
        }
    }

    &.task-form {
        display: block;
        input {

            &[type=text] {
                height: 47px;
                margin-bottom: 15px;
            }

            &[type=submit] {
                margin-left: 0;
            }
        }
    }

    &.player-form, &.task-form {
        margin-top: 15px;
    }
}